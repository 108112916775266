<template>
    <v-data-table :items="datos" :headers="headers" dense group-by="grupo_nombre">
        <template v-slot:group.header="{ group, toggle, isOpen, headers }">
            <td :colspan="headers.length" class="pa-0 highlighted" @click="toggle">
                <v-row no-gutters justify="center">
                    <v-col class="flex-grow-1 flex-shrink-0 fill-height pa-1 px-4">
                        <span :title="group">
                            <v-icon left>group</v-icon>{{ group | see_more(140) }}
                        </span>
                    </v-col>
                    <v-col class="flex-grow-0 flex-shrink-1 pa-1">
                        <v-icon v-if="!isOpen">expand_more</v-icon>
                        <v-icon v-else>expand_less</v-icon>
                    </v-col>
                </v-row>
            </td>
        </template>
        <template v-slot:item.nombre="{ item }">
            <span :title="item.nombre">
                <v-icon :color="color || 'secondary'" left>folder</v-icon>{{ item.nombre }}
            </span>
        </template>
        <template v-slot:item.grupo_nombre="{ item }">
            <span :title="item.grupo_nombre">
                <v-icon left>group</v-icon>{{ item.grupo_nombre | see_more(20) }}
            </span>
        </template>
        <template v-slot:item.grupo_fecha_termino="{ item }">
            {{ item.grupo_fecha_termino | moment("L") }}
        </template>
        <template v-slot:item.fecha_finalizado="{ item }">
            <v-icon color="green" left>check</v-icon>{{ item.fecha_finalizado | moment("L HH:mm") }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn icon :to="{ name: 'dashboard_cap_item', params: { id: item.id } }" depressed class="subtitle-2">
                <v-icon>chevron_right</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: ['value', 'read-only', 'finalizados', 'color', 'details'],
    computed: {
        headers() {
            var heads = [
                { value: "nombre", text: "Curso" },
                { value: "grupo_nombre", text: "Grupo" }
            ]

            if(!!this.details) {
                heads.push({ value: "empresa.nombre", text: "Empresa" });
                heads.push({ value: "gerencia.nombre", text: "Gerencia" });
            }

            if(!!this.finalizados) {
                heads.push({ value: "fecha_finalizado", text: "Finalizado" });
            }

            heads.push({ value: "grupo_fecha_termino", text: "Termino" });

            if(!this.readOnly) {
                heads.push({ value: "actions", text: "" });
            }

            return heads;
        },
        datos() {
            return this.value || [];
        }
    }
}
</script>
<style>
    .highlighted {
        background-color: rgb(138, 149, 207);
    }
</style>